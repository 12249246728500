<template>
  <section id="master-faq">
    <b-row>
      <b-col
        md="3"
        cols="12"
      >
        <b-card>

          <h4 class="mb-1 d-flex justify-content-between align-items-center">
            Kategori
            <b-button
              v-if="userData.group_id === 10"
              variant="outline-info"
              size="sm"
              @click="tambahCat()"
            >
              Tambah Kategori
            </b-button>
          </h4>
          <b-list-group class="mb-2">
            <b-list-group-item
              :active="category_id === ''"
              class="d-flex justify-content-start align-items-center"
              @click="catFilter('')"
            >
              Semua
            </b-list-group-item>
            <b-list-group-item
              v-for="(data, index) in referenceData"
              :key="index"
              :active="data.nama === category_id"
              class="d-flex justify-content-between align-items-center"
              @click="catFilter(data.nama)"
            >
              <div>
                {{ data.nama }}
              </div>
              <b-button
                v-if="userData.group_id === 10"
                variant="flat-warning"
                size="sm"
                @click="editCat(data)"
              >
                <feather-icon
                  icon="EditIcon"
                />
              </b-button>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>

      <b-col
        md="9"
        cols="12"
      >
        <b-card>
          <h4 class="text-center mb-1">
            Pencarian
          </h4>
          <validation-observer ref="simpleRules">
            <b-form @submit.prevent="validationForm">
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-form-group
                    label="Kata Kunci"
                    label-for="vi-keyword"
                  >
                    <b-form-input
                      id="vi-keyword"
                      v-model="keyword"
                      required
                      name="keyword"
                      placeholder="Kata Kunci"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  class="mb-1"
                >
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mr-50"
                  >
                    Cari
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    @click="searchReset"
                  >
                    Reset
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>

          <hr>

          <div class="d-flex justify-content-between mb-1">
            <h3>Pertanyaan Umum</h3>
            <b-button
              v-if="userData.group_id === 10"
              class="btn btn-info btn-sm"
              @click.prevent="tambahTiket"
            >
              Tambah Pertanyaan Umum
            </b-button>
          </div>
          <template v-if="alert">
            <b-alert
              :variant="alertDetail.variant"
              class="mt-2"
              show
            >
              <h4 class="alert-heading">
                {{ alertDetail.title }}
              </h4>
              <div
                v-if="alertDetail.variant === 'danger'"
                class="alert-body"
              >
                <ul v-if="typeof alertDetail.text === 'object'">
                  <li
                    v-for="(item, index) in alertDetail.text"
                    :key="index"
                  >
                    {{ index }}:
                    <span
                      v-for="(child, indexs) in item"
                      :key="indexs"
                    >
                      {{ child }}
                    </span>
                  </li>
                </ul>
                <template v-else>
                  {{ alertDetail.text }}
                </template>
              </div>
              <div
                v-else
                class="alert-body"
              >
                {{ alertDetail.text }}
              </div>
            </b-alert>
          </template>

          <div class="mb-2">
            <div
              v-for="dt in rows"
              :key="dt.faq_id"
              :title="dt.title"
            >
              <div class="border-top mb-0 card-text p-0 d-flex">
                <b-button
                  v-b-toggle
                  :href="`#collapse-infra-${dt.faq_id}`"
                  variant="flat-secondary"
                  class="text-left flex-grow-1"
                  @click.prevent
                >
                  {{ dt.title }}
                  <b-badge
                    variant="light-info"
                    class="mr-50"
                  >
                    {{ dt.kategori }}
                  </b-badge>
                </b-button>
                <b-button
                  v-if="userData.group_id === 10"
                  variant="flat-warning"
                  size="sm"
                  @click="editTiket(dt)"
                >
                  <feather-icon
                    icon="EditIcon"
                  />
                </b-button>
                <b-form-checkbox
                  v-if="userData.group_id === 10"
                  :checked="(dt.status) ? true : false"
                  switch
                  inline
                  class="mt-50"
                  @change="changeStatus(dt)"
                />
              </div>
              <b-collapse
                :id="`collapse-infra-${dt.faq_id}`"
                class="p-1"
              >
                <div
                  v-html="dt.keterangan"
                />
              </b-collapse>
            </div>
          </div>
        </b-card>
      </b-col>
      <!-- post -->
    </b-row>

    <b-modal
      id="modal-tambah-tiket"
      ref="modal-tambah-tiket"
      title="Pertanyaan Umum"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <template v-if="alert">
          <b-alert
            :variant="alertDetail.variant"
            class="mb-1"
            show
          >
            <h4 class="alert-heading">
              {{ alertDetail.title }}
            </h4>
            <div
              v-if="alertDetail.variant === 'danger'"
              class="alert-body"
            >
              <ul v-if="typeof alertDetail.text === 'object'">
                <li
                  v-for="(item, index) in alertDetail.text"
                  :key="index"
                >
                  {{ index }}:
                  <span
                    v-for="(child, indexs) in item"
                    :key="indexs"
                  >
                    {{ child }}
                  </span>
                </li>
              </ul>
              <template v-else>
                {{ alertDetail.text }}
              </template>
            </div>
            <div
              v-else
              class="alert-body"
            >
              {{ alertDetail.text }}
            </div>
          </b-alert>
        </template>
        <validation-observer ref="newTicket">
          <b-form @submit.prevent="checkTicket">
            <b-row>
              <b-col
                md="12"
              >
                <b-form-group
                  label="Pilih Kategori"
                  label-for="vi-category"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Kategori"
                    rules="required"
                  >
                    <v-select
                      id="vi-category"
                      v-model="category"
                      required
                      name="category"
                      :options="referenceData"
                      placeholder="Kategori"
                      label="nama"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
              >
                <b-form-group
                  label="Judul"
                  label-for="vi-title"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Judul"
                    rules="required"
                  >
                    <b-form-input
                      id="vi-title"
                      v-model="content.title"
                      required
                      name="title"
                      placeholder="Judul Pertanyaan"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
              >
                <b-form-group
                  label="Pertanyaan"
                  label-for="vi-keterangan"
                >
                  <quill-editor
                    id="vi-keterangan"
                    v-model="content.keterangan"
                    :options="editorOption"
                    class="mb-1"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mb-1"
              >
                <b-button
                  type="submit"
                  variant="primary"
                >
                  Kirim Pertanyaan
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-tambah-cat"
      ref="modal-tambah-cat"
      title="Kategori"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <template v-if="alert">
          <b-alert
            :variant="alertDetail.variant"
            class="mb-1"
            show
          >
            <h4 class="alert-heading">
              {{ alertDetail.title }}
            </h4>
            <div
              v-if="alertDetail.variant === 'danger'"
              class="alert-body"
            >
              <ul v-if="typeof alertDetail.text === 'object'">
                <li
                  v-for="(item, index) in alertDetail.text"
                  :key="index"
                >
                  {{ index }}:
                  <span
                    v-for="(child, indexs) in item"
                    :key="indexs"
                  >
                    {{ child }}
                  </span>
                </li>
              </ul>
              <template v-else>
                {{ alertDetail.text }}
              </template>
            </div>
            <div
              v-else
              class="alert-body"
            >
              {{ alertDetail.text }}
            </div>
          </b-alert>
        </template>
        <validation-observer ref="newCat">
          <b-form @submit.prevent="checkCat">
            <b-row>
              <b-col
                md="12"
              >
                <b-form-group
                  label="Nama Kategori"
                  label-for="vi-title"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nama Kategori"
                    rules="required"
                  >
                    <b-form-input
                      id="vi-title"
                      v-model="cat.nama"
                      required
                      name="title"
                      placeholder="Nama Kategori"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="mb-1"
              >
                <b-button
                  type="submit"
                  variant="primary"
                >
                  Simpan
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BForm, BRow, BButton, BCol, BCard, BFormGroup, BAlert, VBModal, BModal, BFormCheckbox,
  BFormInput, BCardText, BBadge, BListGroup, BListGroupItem, BCollapse, VBToggle,
} from 'bootstrap-vue'

import { quillEditor } from 'vue-quill-editor'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCollapse,
    BForm,
    BFormInput,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    // BFormSelect,
    BCardText,
    BBadge,
    BButton,
    BModal,
    BAlert,
    BCard,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    quillEditor,
  },
  setup() {
    const toast = useToast()

    const editorOption = {
      theme: 'snow',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['clean', 'link', 'image'],
        ],
      },
      placeholder: 'Pertanyaan Anda',
    }

    return {
      toast,
      editorOption,
    }
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  data() {
    return {
      required,
      isLoading: false,
      userData: getUserData(),
      referenceData: [],
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
      content: {
        title: '',
        keterangan: '',
        status: 1,
      },
      cat: {
        nama: '',
        status: 1,
      },
      category: '',
      keyword: '',
      instansi_id: '',
      category_id: '',
      rows: [],
      totalRecords: 0,
      selectPage: 1,
      order: 'keterangan',
      userSelect: 'all',
      serverParams: {
        sort_by: [
          {
            column: 'title',
            asc_desc: 'ASC',
          },
        ],
        start: 0,
        length: 20,
        asc_desc: 'ASC',
      },
    }
  },
  computed: {
    orderToggleIcon() {
      return this.order === 'ASC' ? 'ChevronsUpIcon' : 'ChevronsDownIcon'
    },
  },
  mounted() {
    this.getReference()
  },
  methods: {
    changeStatus(val) {
      document.getElementById('loading-bg').style.display = 'block'
      const params = {
        faq_id: val.faq_id,
        status: (val.status) ? 0 : 1,
      }
      this.$http.post(`/faq/set-status?token=${localStorage.getItem('userToken')}`, params)
        .then(res => {
          if (res.data.status === 'success') {
            document.getElementById('loading-bg').style.display = 'none'
            this.alert = false
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Status Pertanyaan Umum Berhasil Diubah',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            }, 1500)
          }
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.error !== '') ? error.response.data.error : error,
          }
        })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.updateParams({ keyword: this.keyword })
          this.loadItems()
        }
      })
    },
    searchReset() {
      this.keyword = ''
      this.updateParams({ keyword: '' })
      this.loadItems()
    },
    userFilter(val) {
      this.userSelect = val
      this.category_id = ''
      this.doFilter()
    },
    catFilter(val) {
      this.category_id = val
      this.doFilter()
    },
    doFilter() {
      const params = []
      if (this.userData.group_id !== 10) {
        params.push({
          column: 'status',
          value: '1',
        })
      }
      if (this.category_id !== '') {
        params.push({
          column: 'kategori',
          value: this.category_id,
        })
      }
      this.updateParams({
        filters: params,
      })
      this.loadItems()
    },
    tambahTiket() {
      this.$refs['modal-tambah-tiket'].show()
    },
    editTiket(val) {
      this.referenceData.map(ref => {
        if (ref.nama === val.kategori) {
          this.category = ref
        }
        return true
      })
      this.content = val
      this.$refs['modal-tambah-tiket'].show()
    },
    checkTicket() {
      if (!this.content.keterangan) {
        this.alert = true
        this.alertDetail = {
          variant: 'danger',
          title: 'Error Found',
          text: 'Kolom Pertanyaan Tidak Boleh Kosong',
        }
      } else {
        this.$refs.newTicket.validate().then(success => {
          if (success) {
            this.saveTicket()
          }
        })
      }
    },
    saveTicket() {
      document.getElementById('loading-bg').style.display = 'block'
      const params = {
        category_id: this.category.faq_category_id,
        title: this.content.title,
        keterangan: this.content.keterangan,
        status: this.content.status,
      }
      if (this.content.faq_id) {
        params.faq_id = this.content.faq_id
      }
      this.$http.post(`/faq/add?token=${localStorage.getItem('userToken')}`, params)
        .then(res => {
          if (res.data.status === 'success') {
            this.content = {
              title: '',
              keterangan: '',
              status: 1,
            }

            this.alert = false
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Pertanyaan Umum Berhasil Disimpan',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            }, 1500)

            setTimeout(() => {
              this.$refs['modal-tambah-tiket'].hide()
              document.getElementById('loading-bg').style.display = 'none'
              this.loadItems()
            }, 1000)
          }
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.error !== '') ? error.response.data.error : error,
          }
        })
    },
    tambahCat() {
      this.$refs['modal-tambah-cat'].show()
    },
    editCat(val) {
      this.cat = val
      this.$refs['modal-tambah-cat'].show()
    },
    checkCat() {
      this.$refs.newCat.validate().then(success => {
        if (success) {
          this.saveCat()
        }
      })
    },
    saveCat() {
      document.getElementById('loading-bg').style.display = 'block'
      const params = {
        nama: this.cat.nama,
        status: this.cat.status,
      }
      if (this.cat.faq_category_id) {
        params.faq_category_id = this.cat.faq_category_id
      }
      this.$http.post(`/faq/add-category?token=${localStorage.getItem('userToken')}`, params)
        .then(res => {
          if (res.data.status === 'success') {
            this.alert = false
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Kategori Berhasil Disimpan',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            }, 1500)

            setTimeout(() => {
              this.$refs['modal-tambah-cat'].hide()
              document.getElementById('loading-bg').style.display = 'none'
              this.category_id = ''
              this.getReference()
            }, 1000)
          }
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.error !== '') ? error.response.data.error : error,
          }
        })
    },
    getReference() {
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.get('/faq/categories', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data
            this.doFilter()
          }
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.error !== '') ? error.response.data.error : error,
          }
        })
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange() {
      this.updateParams({ start: (this.selectPage - 1) * this.serverParams.length })
      this.loadItems()
    },
    loadItems() {
      document.getElementById('loading-bg').style.display = 'block'
      this.rows = []
      this.updateParams({ keyword: this.keyword })
      this.$http.post(`/faq/list?token=${localStorage.getItem('userToken')}`, this.serverParams)
        .then(res => {
          document.getElementById('loading-bg').style.display = 'none'
          if (res.data.status === 'success') {
            this.alert = false
            this.rows = res.data.data
            this.totalRecords = res.data.data.length
          } else {
            this.rows = []
            this.totalRecords = 0
          }
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.error !== '') ? error.response.data.error : error,
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';

  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-radius: 0;
      border-top: none !important;

      .ql-editor {
        max-height: 40rem;
        overflow-y: scroll;
      }
    }
  }
</style>
